<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start">
        <div class="mb-2">
          <div>
            <Label>START / STOP by student </Label>
            <CustomSelect
              v-if="rooms"
              v-model="state.form.start_and_stop"
              :invalid-feedback="
                state.serverErrors.room_id || errors.start_and_stop
              "
              :options="start_and_stop_rooms"
              placeholder="Select location"
              :clearable="false"
              :reduce="(item) => item.value.id"
              multiple
              :clear-search-on-blur="
                () => {
                  return true
                }
              "
              :key="componentKey"
            ></CustomSelect>
            <InfoBox class="danger mt-2" v-if="state.serverErrors.room_id">
              {{ state.serverErrors.room_id[0] }}
            </InfoBox>
          </div>

          <div class="mt-3">
            <Label>only START by student </Label>
            <CustomSelect
              v-if="rooms"
              :invalid-feedback="
                state.serverErrors.room_id || errors.start_only
              "
              v-model="state.form.start_only"
              :options="start_only_rooms"
              placeholder="Select location"
              :clearable="false"
              :reduce="(item) => item.value.id"
              multiple
              :clear-search-on-blur="
                () => {
                  return true
                }
              "
              :key="componentKey"
            >
            </CustomSelect>
            <InfoBox class="danger mt-2" v-if="state.serverErrors.room_id">
              {{ state.serverErrors.room_id[0] }}
            </InfoBox>
          </div>

          <div class="mt-3">
            <Label>only STOP by student</Label>
            <CustomSelect
              v-if="rooms"
              :invalid-feedback="state.serverErrors.room_id || errors.stop_only"
              v-model="state.form.stop_only"
              :options="stop_only_rooms"
              placeholder="Select location"
              :clearable="false"
              :reduce="(item) => item.value.id"
              multiple
              :clear-search-on-blur="
                () => {
                  return true
                }
              "
              :key="componentKey"
            ></CustomSelect>
            <InfoBox class="danger mt-2" v-if="state.serverErrors.room_id">
              {{ state.serverErrors.room_id[0] }}
            </InfoBox>
          </div>

          <div class="mt-3">
            <Label>Set limit: </Label>
            <CustomSelect
              class="w-50"
              v-model="state.form.limit"
              :invalid-feedback="state.serverErrors.limit || errors.limit"
              :options="limitOptions"
              placeholder="Select limit"
              :clearable="false"
              :reduce="(item) => item.value"
              :disabled="!isSelectedRoom"
              :clear-search-on-blur="
                () => {
                  return true
                }
              "
            ></CustomSelect>
            <InfoBox class="danger mt-2" v-if="state.serverErrors.limit">
              {{ state.serverErrors.limit[0] }}
            </InfoBox>
          </div>

          <div>
            <InfoBox
              v-if="state.serverRes"
              class="mt-4"
              :class="{ danger: Object.keys(state.serverErrors).length }"
              :title="state.serverRes.title"
              >{{ state.serverRes.message }}</InfoBox
            >
          </div>

          <div class="text-center mt-3">
            <LoadingButton
              :is-loading="state.isLoading"
              @click="submit()"
              solid
              rounded
              >Submit</LoadingButton
            >
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { computed, reactive, onMounted, ref } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import Label from "@/v3components/shared/Form/Label"

export default {
  name: "CreateAutoPassLimit",
  components: { InfoBox, LoadingButton, CustomSelect, Label },
  props: ["isInView", "limit"],
  emits: ["autoPassLimitCreated", "cancel", "closeModal"],
  setup(props, { emit }) {
    const store = useStore()

    const componentKey = ref(0)

    const state = reactive({
      form: {
        start_and_stop: [],
        start_only: [],
        stop_only: [],
        limit: {}
      },
      serverErrors: {},
      serverRes: null,
      isLoading: false
    })

    const rooms = computed(
      () => store.getters["dashboardTable/formListAutoPassesRooms"]
    )

    const autoPassLimits = computed(() => store.getters["rooms/autoPassLimits"])

    const limitOptions = computed(() => {
      const options = []
      options.push(
        { label: "Turn off", value: -1 },
        { label: "No limit", value: 0 }
      )
      for (let i = 1; i < 21; i++) {
        options.push({ label: i, value: i })
      }
      return options
    })

    const start_and_stop_rooms = computed(() => {
      return state.form.start_only.length
        ? rooms.value.filter(
            (el) =>
              !state.form.start_only.includes(el.value.id) &&
              !state.form.stop_only.includes(el.value.id)
          )
        : rooms.value
    })

    const start_only_rooms = computed(() => {
      return state.form.start_and_stop.length
        ? rooms.value.filter(
            (el) =>
              !state.form.start_and_stop.includes(el.value.id) &&
              !state.form.stop_only.includes(el.value.id)
          )
        : rooms.value
    })

    const stop_only_rooms = computed(() => {
      return state.form.start_and_stop.length
        ? rooms.value.filter(
            (el) =>
              !state.form.start_and_stop.includes(el.value.id) &&
              !state.form.start_only.includes(el.value.id)
          )
        : rooms.value
    })

    const isSelectedRoom = computed(() => {
      return Boolean(
        state.form.start_and_stop.length ||
          state.form.start_only.length ||
          state.form.stop_only.length
      )
    })

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        start_and_stop: {},
        start_only: {},
        stop_only: {},
        limit: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      state.form.limit = limitOptions.value[1]
      store.dispatch("rooms/getAutoPassLimit").then((response) => {
        const data = response.data.data
        if (
          data.auto_pass_preference_to_user &&
          data.auto_pass_preference_to_user.length
        ) {
          setAutoPassLimitData(data)
        }
      })
    })

    const setAutoPassLimitData = (data) => {
      data.auto_pass_preference_to_user.forEach((el) => {
        if (
          el.auto_pass.room_id &&
          !state.form.start_only.includes(el.auto_pass.room_id) &&
          !state.form.stop_only.includes(el.auto_pass.room_id) &&
          !state.form.start_and_stop.includes(el.auto_pass.room_id) &&
          el.mode in state.form
        ) {
          state.form[el.mode].push(el.auto_pass.room_id)
        }
      })
      if (props.limit) {
        state.form.limit = props.limit
      } else {
        state.form.limit = props.limit == 0 ? 0 : -1
      }
      componentKey.value++
    }

    const submit = () => {
      setResponseInfoBox()
      if (isValid.value) {
        const data = {
          limit: parseInt(state.form.limit),
          rooms_and_modes: []
        }
        if (state.form.start_and_stop && state.form.start_and_stop.length) {
          state.form.start_and_stop.forEach((room) => {
            data.rooms_and_modes.push({ room_id: room, mode: "start_and_stop" })
          })
        }
        if (state.form.start_only && state.form.start_only.length) {
          state.form.start_only.forEach((room) => {
            data.rooms_and_modes.push({ room_id: room, mode: "start_only" })
          })
        }
        if (state.form.stop_only?.length) {
          state.form.stop_only.forEach((room) => {
            data.rooms_and_modes.push({ room_id: room, mode: "stop_only" })
          })
        }
        state.isLoading = true
        store
          .dispatch("rooms/addAutoPassLimit", data)
          .then(() => {
            setResponseInfoBox("Success", "Successfully saved!")
            refreshAutoPassLimits()
            setTimeout(() => {
              setResponseInfoBox()

              emit("cancel")
              emit("closeModal")
            }, 850)
            state.isLoading = false
          })
          .catch((err) => {
            const res = err.response.data
            state.serverErrors = res.errors ? res.errors : {}
            setResponseInfoBox("Error", res.message)
            state.isLoading = false
          })
      } else {
        v$.value.$touch()
      }
    }

    const refreshAutoPassLimits = () => {
      store.dispatch("dashboardTable/getDashboard").then((res) => {
        const data = res.data.data
        if (data && data.autoPassesPreference) {
          const auto_pass_preference_to_user = data.autoPassesPreference
          store.commit("rooms/SET_AUTO_PASS_LIMITS", {
            ...autoPassLimits.value,
            auto_pass_preference_to_user
          })
        }
      })
    }

    const resetForm = () => {
      v$.value.$reset()
      state.form = {
        start_and_stop: [],
        start_only: [],
        stop_only: [],
        limit: limitOptions.value[0]
      }
    }

    const refreshForm = () => {
      setTimeout(() => {
        resetForm()
        setResponseInfoBox()

        if (autoPassLimits.value) {
          setAutoPassLimitData(autoPassLimits.value)
        }
      }, 500)
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    return {
      state,
      rooms,
      autoPassLimits,
      limitOptions,
      start_and_stop_rooms,
      start_only_rooms,
      stop_only_rooms,
      isSelectedRoom,
      setAutoPassLimitData,
      submit,
      refreshAutoPassLimits,
      refreshForm,
      errors,
      componentKey
    }
  }
}
</script>
