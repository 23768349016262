<template>
  <div>
    <div v-if="pass && state.readyToRender" class="edit-pass-time-form p-4">
      <div class="pass-time-form mt-3">
        <!-- In/Out times -->
        <div class="row">
          <div class="col">
            <Label>Out time</Label>
            <CustomTimePicker
              format="HH:mm"
              :invalid-feedback="state.serverErrors.out_time"
              v-model="state.form.out_time"
            ></CustomTimePicker>
          </div>
          <div class="col">
            <Label>In time</Label>
            <CustomTimePicker
              format="HH:mm"
              :invalid-feedback="state.serverErrors.in_time"
              v-model="state.form.in_time"
            ></CustomTimePicker>
          </div>
        </div>
        <!-- In/Out times child -->
        <div v-if="pass.child" class="row mt-4">
          <div class="col">
            <Label>Out time</Label>
            <CustomTimePicker
              format="HH:mm"
              :invalid-feedback="state.serverErrors.child_out_time"
              v-model="state.form.child_out_time"
            ></CustomTimePicker>
          </div>
          <div class="col">
            <div v-if="pass.child.completed_at">
              <Label>In time</Label>
              <CustomTimePicker
                format="HH:mm"
                :invalid-feedback="state.serverErrors.child_in_time"
                v-model="state.form.child_in_time"
              ></CustomTimePicker>
            </div>
          </div>
        </div>
        <div
          v-if="isUserAllowTardy"
          class="tardy-late-pass d-flex flex-column mt-4"
        >
          <p class="mb-1">Mark this pass as:</p>
          <TardyRadioGroup v-model="state.form.tardy_badge" />
        </div>

        <InfoBox
          v-if="state.serverRes"
          class="mt-3"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
    </div>

    <div class="text-center">
      <LoadingButton class="me-2 px-4" @click="onCancel" rounded
        >Cancel</LoadingButton
      >
      <LoadingButton
        :is-loading="state.isProcessing"
        @click="changePassHistoryTime()"
        class="mt-4 mb-2"
        solid
        rounded
        >Update</LoadingButton
      >
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from "vue"
import { useStore } from "vuex"
import helpers from "@/helpers/index"
import Label from "@/v3components/shared/Form/Label"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker"
import TardyRadioGroup from "@/v3components/Forms/TardyRadioGroup"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"

export default {
  name: "EditPassHistoryTimeForm",
  components: {
    Label,
    CustomTimePicker,
    TardyRadioGroup,
    LoadingButton,
    InfoBox
  },
  props: {
    pass: {
      type: Object,
      required: true
    },
    v3dashboard: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      form: {
        out_time: "",
        in_time: "",
        child_out_time: "",
        child_in_time: "",
        tardy_badge: ""
      },
      isProcessing: false,
      readyToRender: false,
      serverErrors: {},
      serverRes: null
    })

    const tardyManagementOptions = computed(
      () => store.getters["schools/tardyManagementOptions"]
    )
    const currentUser = computed(() => store.getters["authentication/user"])

    const isUserAllowTardy = computed(() => {
      if (tardyManagementOptions.value != null) {
        if (tardyManagementOptions.value.usage["within-ehp"]) {
          if (currentUser.value.role.name == "admin") {
            return true
          } else if (
            (currentUser.value.role.name == "staff" ||
              currentUser.value.role.name == "teacher") &&
            tardyManagementOptions.value["allow-teacher-staff"]
          ) {
            return true
          } else if (
            currentUser.value.allow_tardy &&
            tardyManagementOptions.value["allow-selected-users-only"]
          ) {
            return true
          }
          return false
        }
        return false
      }
      return false
    })

    const passStateTimePeriods = computed(() => {
      return props.pass.child
        ? props.pass.child.completed_at
          ? [
              state.form.out_time,
              state.form.in_time,
              state.form.child_out_time,
              state.form.child_in_time
            ]
          : [state.form.out_time, state.form.in_time, state.form.child_out_time]
        : [state.form.out_time, state.form.in_time]
    })

    watch(
      () => props.pass,
      (value) => {
        setTimeout(() => {
          if (value) {
            setCurrentPassTime(value)
          }
        }, 0)
      }
    )

    onMounted(() => {
      state.readyToRender = true
      setCurrentPassTime(props.pass)
    })

    const validTimePeriods = () => {
      return !passStateTimePeriods.value.some(
        (periodValue, index) =>
          periodValue > passStateTimePeriods.value[index + 1]
      )
    }

    const setCurrentPassTime = (pass) => {
      if (pass) {
        state.form.out_time = pass.approved_at
          ? helpers.transformDate(pass.approved_at, "HH:mm")
          : state.form.out_time
        state.form.in_time = pass.completed_at
          ? helpers.transformDate(pass.completed_at, "HH:mm")
          : state.form.in_time
        state.form.tardy_badge = pass.tardy_badge
        if (pass.child) {
          state.form.child_out_time = pass.child.approved_at
            ? helpers.transformDate(pass.child.approved_at, "HH:mm")
            : state.form.child_out_time
          state.form.child_in_time = pass.child.completed_at
            ? helpers.transformDate(pass.child.completed_at, "HH:mm")
            : state.form.child_in_time
        }
      }
    }

    const changePassHistoryTime = () => {
      if (!validTimePeriods()) {
        setResponseInfoBox(
          "Incorrect time periods",
          `Out time must be before In time${
            props.pass.child
              ? " and returning Pass times can't be before initial Pass times."
              : ""
          }`
        )
        return
      }
      state.isProcessing = true
      const data = {
        passID: props.pass.id,
        newTime: state.form
      }
      store
        .dispatch("passes/changePassHistoryTime", data)
        .then((response) => {
          const data = response.data.data
          setResponseInfoBox("Success", "Successfully updated!")
          state.isProcessing = false
          state.serverErrors = {}
          if (props.v3dashboard) {
            store.commit("dashboardTable/UPDATE_PASS", data)
            store.dispatch("dashboardCounters/getPassesForCounters")
          } else {
            store.commit("passes/UPDATE_PASS_HISTORY", data)
          }
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors || {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      emit("cancel")
    }

    return {
      state,
      tardyManagementOptions,
      currentUser,
      isUserAllowTardy,
      passStateTimePeriods,
      changePassHistoryTime,
      setResponseInfoBox,
      onCancel
    }
  }
}
</script>
