<template>
  <CContainer>
    <CRow
      v-if="!state.showTodaysHistory"
      data-test-id="create-teacher-pass-form"
      class="justify-content-center"
    >
      <CCol md="12" class="text-start px-0">
        <CCol class="mt-4">
          <Label class="v3teacher-pass-label" required> Student </Label>
          <LazyLoadSelect
            data-test-id="teacher-pass-select-student"
            :is-multiple="false"
            :selected="state.form.user_id"
            :invalid-feedback="errors.user_id"
            type="student"
            placeholder="Search students"
            @changed="
              (value) => {
                state.form.user_id = value
              }
            "
          >
          </LazyLoadSelect>
          <InfoBox v-if="state.serverErrors.user_id" class="danger mt-2">
            {{ state.serverErrors.user_id[0] }}
          </InfoBox>
          <InfoBox
            v-if="
              state.form.user_id &&
              state.userInfo &&
              state.userInfo.polarity_active
            "
            class="danger mt-2"
          >
            The hall is currently busy for this student. Please see your
            building admin for any questions.
          </InfoBox>
          <InfoBox
            v-if="
              state.form.user_id &&
              state.userInfo &&
              state.userInfo.show_active_pass_limit_message
            "
            class="danger mt-2"
          >
            Active Pass Limit has been reached. By clicking create, you will
            exceed the limit.
          </InfoBox>

          <CCol v-if="state.form.user_id" class="mt-2 v3teacher-pass-today-box">
            <div>
              Today's Passes:
              <span class="box cursor-pointer ms-1" @click="showPassesModal"
                >{{ state.userInfo && state.userInfo.user_passes_count }}
                <i class="ri-door-open-line"
              /></span>
              <span v-if="state.userInfo && state.userInfo.passes_remain">
                The student has {{ state.userInfo.passes_remain }} pass(es)
                remaining today.
              </span>
            </div>
          </CCol>
        </CCol>
        <CCol class="mt-2">
          <Label class="v3teacher-pass-label" required>
            Where do you want to send the student?
          </Label>
          <FavoriteCarousel
            class="mb-3"
            :key="'destination'"
            :teacher="true"
            section="destination"
            :not-managable="true"
            classes="pb-0 pt-0 mt-2 smaller-size"
            :action="(elem) => getCapacityReached(elem)"
            :selected="state.form.to_id"
          />
          <LazyLoadSelect
            data-test-id="teacher-pass-select-destination"
            :is-multiple="false"
            :selected="state.form.to_id"
            :invalid-feedback="errors.to_id"
            placeholder="To:"
            type="combined"
            :adult-pass-request-disabled="true"
            :force-clear-cache="true"
            @changed="(elem) => getCapacityReached(elem)"
          >
          </LazyLoadSelect>
          <InfoBox class="danger mt-2" v-if="state.serverErrors.to_id">
            {{ state.serverErrors.to_id[0] }}
          </InfoBox>

          <InfoBox class="danger mt-2" v-if="state.serverErrors.to_type">
            {{ state.serverErrors.to_type[0] }}
          </InfoBox>
          <InfoBox
            v-if="
              state.form.to_id &&
              state.form.to_id.value &&
              state.form.to_id.value.unavailability
            "
            class="danger mt-2"
          >
            This adult is unavailable until
            <span
              class="mx-1"
              v-html="
                $helpers.getHTMLDateTime(
                  state.form.to_id.value.unavailability.to_date,
                  'MMMM D, YYYY',
                  'h:mm A'
                ) + '.'
              "
            />
            <span v-if="state.form.to_id.value.unavailability.comment"
              >Note: {{ state.form.to_id.value.unavailability.comment }}</span
            ></InfoBox
          >
          <InfoBox v-if="isCurrRoomRestricted" class="danger mt-2">
            This location is restricted for this student. By clicking create you
            will override this restriction.
          </InfoBox>
          <InfoBox v-if="toCapacityReached" class="danger mt-2">
            Maximum capacity for the location has been reached. By clicking
            create you will exceed the max.
          </InfoBox>
        </CCol>
        <CCol class="mt-2">
          <Label class="v3teacher-pass-label"> Reason </Label>
          <InputField
            data-test-id="teacher-pass-input-reason"
            v-model="state.form.comment"
            max-chars="250"
            :rows="4"
            class="w-full"
            type="textarea"
          />
        </CCol>
        <CCol class="mt-2">
          <CustomSwitch
            v-model="state.form.auto_approve"
            test-id="teacher-pass-auto-approve-switch"
          />
          <span class="ms-2 v3teacher-pass-label">Pre-approve pass</span>
        </CCol>
        <CCol class="mt-2">
          <CCol class="mt-2">
            <div v-if="isUserAllowTardy" class="d-flex flex-column mt-2">
              <div class="mb-1 v3teacher-pass-label">Mark this pass as:</div>
              <TardyRadioGroup v-model="state.form.tardy_badge" />
            </div>
          </CCol>

          <CCol class="mt-2">
            <InfoBox
              data-test-id="teacher-pass-info-box"
              v-if="state.serverRes"
              class="danger mt-4"
              >{{ state.serverRes.message }}</InfoBox
            ></CCol
          >

          <div v-if="!isInView">
            <div
              class="d-flex align-items-center justify-content-center mt-4 mb-1 text-center"
            >
              <LoadingButton class="mx-2" @click="closeModal" rounded
                >Cancel</LoadingButton
              >
              <LoadingButton
                @click="submit()"
                :is-loading="state.isLoading"
                solid
                rounded
                class="me-2"
              >
                Create
              </LoadingButton>
              <router-link class="text-graident-blue" to="/teacher-pass">
                <div class="d-flex align-items-center" @click="closeModal()">
                  <img
                    width="18"
                    class="m-0 me-2"
                    src="@/assets/images/icons/gradient-link.png"
                  />
                  <span class="text-graident-blue">Go to full menu</span>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else class="mt-4">
            <LoadingButton
              data-test-id="teacher-pass-button-create"
              @click="submit()"
              :is-loading="state.isLoading"
              solid
              rounded
              class="me-3 px-4"
            >
              Create
            </LoadingButton>
          </div>
        </CCol>
      </CCol>
    </CRow>

    <div v-if="state.showTodaysHistory && state.form && state.form.user_id">
      <hr class="mt-2" />
      <CRow class="m-2">
        <CCol class="d-flex justify-content-start align-items-center cursor">
          <LoadingButton
            class="align-items-center pe-3"
            @click="showPassesModal"
            solid
            rounded
          >
            <i class="ri-arrow-left-line me-1 font-sm" />Back
          </LoadingButton>
        </CCol>
        <CCol class="d-flex justify-content-center mt-2">
          <h4>Today's History</h4>
        </CCol>
        <CCol class="d-flex justify-content-end align-items-center">
          <HelpCenterButton content-key="todays_history"> </HelpCenterButton>
        </CCol>
      </CRow>

      <StudentPassHistory
        data-test-id="teacher-pass-todays-history"
        :set-data="true"
        :user-id="state.form.user_id.value.id"
        :is-in-modal="true"
      />
    </div>
  </CContainer>
</template>

<script>
import { reactive, computed, onMounted, inject, watch } from "vue"
import { useStore } from "vuex"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import { useRouter } from "vue-router"
import useVuelidate from "@vuelidate/core"
import StudentPassHistory from "@/v3components/Forms/Students/StudentPassHistory"
import TardyRadioGroup from "@/v3components/Forms/TardyRadioGroup"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import FavoriteCarousel from "../FavoriteCarousel.vue"
import Label from "@/v3components/shared/Form/Label"
import InputField from "@/v3components/shared/Form/InputField"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import HelpCenterButton from "@/v3components/HelpCenterButton"

export default {
  name: "CreateTeacherPassForm",
  components: {
    TardyRadioGroup,
    LazyLoadSelect,
    FavoriteCarousel,
    Label,
    InputField,
    CustomSwitch,
    LoadingButton,
    InfoBox,
    StudentPassHistory,
    HelpCenterButton
  },
  props: ["isInView"],
  emits: ["closeModal", "cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")
    const route = useRouter()

    const state = reactive({
      isLoading: false,
      form: {
        user_id: null,
        to_id: [],
        comment: "",
        auto_approve: true,
        tardy_badge: ""
      },
      serverErrors: {},
      serverRes: null,
      modal: {
        isVisible: {
          todayPasses: false
        }
      },
      userInfo: null,
      polarityMessage: null,
      isCapacityReached: false,
      showTodaysHistory: false
    })

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      form: {
        user_id: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        to_id: {
          required: helpers.withMessage(validationMessages.required, required),
          isRestricted: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              if (
                state.form.to_id &&
                state.form.to_id.value &&
                !state.form.to_id.value.isRestricted
              ) {
                return true
              }
              return false
            })
          )
        },
        auto_approve: {}
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      if (currentUser.value.role == "admin") {
        store.dispatch("polarities/getPolarityMessage").then((res) => {
          if (res && res.data && res.data.data) {
            state.polarityMessage = res.data.data.message
              ? res.data.data.message
              : res.data.data.initial_default_value
          }
        })
      }
    })

    const currentUser = computed(() => store.getters["authentication/user"])

    const tardyManagementOptions = computed(
      () => store.getters["schools/tardyManagementOptions"]
    )

    const toCapacityReached = computed(() => {
      return state.form && state.form.to_id && state.isCapacityReached
    })

    const isCurrRoomRestricted = computed(() => {
      return (
        state.userInfo &&
        state.form.to_id &&
        state.form.to_id.value &&
        state.form.to_id.value.id &&
        state.userInfo.restricted_rooms &&
        state.userInfo.restricted_rooms.includes(state.form.to_id.value.id)
      )
    })

    const activeDashboardSettings = computed(() => {
      let current = localStorage.getItem("dashSettings")
      if (current) {
        current = JSON.parse(current)
        current.activePassState = "only_active"
        return false
      }
      return current
    })

    const isUserAllowTardy = computed(() => {
      if (tardyManagementOptions.value != null) {
        if (tardyManagementOptions.value.usage["within-ehp"]) {
          if (currentUser.value.role.name == "admin") {
            return true
          } else if (
            (currentUser.value.role.name == "staff" ||
              currentUser.value.role.name == "teacher") &&
            tardyManagementOptions.value["allow-teacher-staff"]
          ) {
            return true
          } else if (
            currentUser.value.allow_tardy &&
            tardyManagementOptions.value["allow-selected-users-only"]
          ) {
            return true
          }
          return false
        }
        return false
      }
      return false
    })

    const getCapacityReached = (value) => {
      state.form.to_id = value
      state.isCapacityReached = false
      if (value.value.type === "App\\Models\\Room") {
        store
          .dispatch("rooms/getCapacityReached", value.value.id)
          .then((response) => {
            if (response.data) {
              const data = response.data.data
              //if limit is set to "-" the room is unlimited
              state.isCapacityReached =
                data.capacity_reached && data.limit != "-"
            }
          })
      }
      store
        .dispatch("rooms/getUnavailability", {
          id: value.value.id,
          type: value.value.type
        })
        .then((response) => {
          if (response.data.data) {
            state.form.to_id.value.unavailability = response.data.data
          }
        })
    }

    const submit = () => {
      setResponseInfoBox()
      if (isValid.value) {
        const pass = {
          user_id: state.form.user_id.value.id,
          to_id: state.form.to_id.id
            ? state.form.to_id.id
            : state.form.to_id.value.id,
          to_type: state.form.to_id.type
            ? state.form.to_id.type
            : state.form.to_id.value.type,
          comment: state.form.comment,
          auto_approve: state.form.auto_approve,
          tardy_badge: state.form.tardy_badge
        }
        state.isLoading = true
        store
          .dispatch("proxyPasses/createProxyPass", pass)
          .then(() => {
            setTimeout(() => {
              state.serverErrors = {}
              localStorage.removeItem("dashSettings")
              state.isLoading = false
              closeModal()
              route.push("/dashboard")
            }, 500)
          })
          .catch((err) => {
            const response = err.response.data
            state.serverErrors = response.errors ? response.errors : {}
            setResponseInfoBox("Error", response.message)
            state.isLoading = false
          })
      } else {
        v$.value.$touch()
      }
    }

    const resetForm = () => {
      state.form = {
        user_id: null,
        to_id: [],
        comment: "",
        auto_approve: true,
        tardy_badge: ""
      }
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const showPassesModal = () => {
      if (props.isInView) {
        modal.open(StudentPassHistory, {
          size: "lg",
          title: "Today's History",
          props: {
            setData: true,
            userId: state.form.user_id.value.id
          },
          help: "todays_history"
        })
      } else {
        state.showTodaysHistory = !state.showTodaysHistory
      }
    }

    const closeModal = () => {
      resetForm()
      setResponseInfoBox()
      emit("closeModal")
      emit("cancel")
    }

    watch(
      () => state.form.user_id,
      (val) => {
        if (val && val.value) {
          state.userInfo = null
          store
            .dispatch("proxyPasses/getProxyPassInfo", val.value.id)
            .then((res) => {
              state.userInfo = res
            })
        }
      }
    )

    return {
      store,
      state,
      errors,
      currentUser,
      tardyManagementOptions,
      toCapacityReached,
      isCurrRoomRestricted,
      activeDashboardSettings,
      isUserAllowTardy,
      getCapacityReached,
      submit,
      showPassesModal,
      closeModal,
      resetForm,
      props,
      setResponseInfoBox
    }
  }
}
</script>
