<template>
  <div class="create-substitute-form">
    <div class="mb-3">
      <Label>Title</Label>
      <CustomSelect
        v-model="state.form.user_initials"
        class="w-full"
        :options="state.user_initials"
        :close-on-select="true"
        :reduce="(item) => item.value"
        :invalid-feedback="state.serverErrors.user_initials"
      ></CustomSelect>
    </div>
    <div class="mb-3">
      <Label required>First name</Label>
      <InputField
        v-model="state.form.first_name"
        class="w-full"
        :invalid-feedback="errors.first_name || state.serverErrors.first_name"
      ></InputField>
    </div>
    <div class="mb-3">
      <Label required>Last name</Label>
      <InputField
        v-model="state.form.last_name"
        class="w-full"
        :invalid-feedback="errors.last_name || state.serverErrors.last_name"
      ></InputField>
    </div>
    <div v-if="isEditMode" class="mb-3">
      <Label>Status</Label>
      <CustomSelect
        v-model="state.form.status"
        class="w-full"
        :options="state.user_statuses"
        :close-on-select="true"
        :reduce="(item) => item.value"
        :invalid-feedback="state.serverErrors.status"
      ></CustomSelect>
    </div>
    <div class="mb-3">
      <div class="d-flex">
        <Label required>PIN</Label>
        <HelpCenterButton
          width="16"
          height="16"
          classes="ms-2"
          styles="line-height: 16px"
          content-key="form_add_substitute_pin_only"
        />
      </div>
      <InputField
        v-model="state.form.pin"
        class="w-full"
        placeholder="Enter the PIN or"
        :append-button="{ icon: 'ri-shield-cross-line', text: 'Generate PIN' }"
        @click-append-button="generatePIN"
        :invalid-feedback="errors.pin || state.serverErrors.pin"
      ></InputField>
    </div>

    <InfoBox
      v-if="state.serverRes"
      class="mb-3"
      :class="{ danger: Object.keys(state.serverErrors).length }"
      :title="state.serverRes.title"
      >{{ state.serverRes.message }}</InfoBox
    >

    <div class="text-center">
      <LoadingButton
        v-if="isEditMode"
        class="me-2 px-4"
        @click="cancelEdit"
        rounded
        >Cancel</LoadingButton
      >
      <LoadingButton
        :is-loading="state.isLoading"
        @click="submit(!isEditMode)"
        class="mt-4 px-4"
        solid
        rounded
        >{{ isEditMode ? "Update" : "Create" }}</LoadingButton
      >
    </div>
  </div>
</template>

<script>
import HelpCenterButton from "@/v3components/HelpCenterButton"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import InputField from "@/v3components/shared/Form/InputField"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import Label from "@/v3components/shared/Form/Label"
import { helpers, required } from "@vuelidate/validators"
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core"
import axios from "axios"

export default {
  name: "CreateSubstituteForm",
  components: {
    Label,
    CustomSelect,
    InputField,
    HelpCenterButton,
    InfoBox,
    LoadingButton
  },
  props: {
    isInView: {
      type: Boolean,
      default: false
    },
    editableMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closeModal", "cancel", "substituteCreated"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isMounted: false,
      isLoading: false,
      serverErrors: {},
      serverRes: null,
      form: {
        user_initials: null,
        first_name: "",
        last_name: "",
        pin: "",
        status: 1
      },
      user_initials: [
        { value: null, label: "None" },
        { value: "Mr.", label: "Mr." },
        { value: "Mrs.", label: "Mrs." },
        { value: "MSgt", label: "MSgt" },
        { value: "Ms.", label: "Ms." },
        { value: "Mx.", label: "Mx." },
        { value: "Miss", label: "Miss" },
        { value: "Dr.", label: "Dr." },
        { value: "Principal", label: "Principal" },
        { value: "Assistant Principal", label: "Assistant Principal" },
        { value: "Dean of Students", label: "Dean of Students" },
        { value: "Br.", label: "Br." },
        { value: "Sr.", label: "Sr." },
        { value: "Cpt", label: "Cpt" },
        { value: "Maj", label: "Maj" },
        { value: "Lt Col", label: "Lt Col" },
        { value: "Col", label: "Col" },
        { value: "Sgt", label: "Sgt" },
        { value: "SSG", label: "SSG" },
        { value: "SFC", label: "SFC" },
        { value: "Coach", label: "Coach" },
        { value: "Rabbi", label: "Rabbi" }
      ],
      user_statuses: [
        { value: 1, label: "Active" },
        { value: 0, label: "Inactive" }
      ]
    })

    const editableSubstitute = computed(
      () => store.getters["users/editableSubstitute"]
    )

    const isEditMode = computed(
      () => !!editableSubstitute.value && props.editableMode
    )

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      form: {
        first_name: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        last_name: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        pin: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      state.isMounted = true
      if (isEditMode.value) {
        setFormByUserRecord(editableSubstitute.value)
      }
    })

    const submit = (isCreate) => {
      setResponseInfoBox()
      if (isValid.value) {
        if (!isCreate) {
          updateSubstitute(editableSubstitute.value.id)
        } else {
          addSubstitute()
        }
      } else {
        v$.value.$touch()
      }
    }

    const addSubstitute = () => {
      state.isLoading = true
      store
        .dispatch("users/createSubstituteTeacher", {
          ...state.form
        })
        .then((response) => {
          const data = response.data
          if (data) {
            store.commit("users/PUSH_USER", data.data)
            setResponseInfoBox("Success", "Successfully created.")
          }
          state.isLoading = false
          state.serverErrors = {}
          resetForm()
          setTimeout(() => {
            emit("cancel")
            emit("substituteCreated")
            setResponseInfoBox()
          }, 1000)
        })
        .catch((err) => {
          state.isLoading = false
          const response = err.response.data
          state.serverErrors = response.errors ? response.errors : {}
          setResponseInfoBox("Error", response.message)
        })
    }

    const updateSubstitute = (userId) => {
      state.isLoading = true
      const data = state.form
      store
        .dispatch("users/updateSubstituteTeacher", { userId, data })
        .then(() => {
          state.isLoading = false
          setResponseInfoBox("Success", "Successfully updated.")
          state.serverErrors = {}
          resetForm()
          setTimeout(() => {
            cancelEdit()
            setResponseInfoBox()
          }, 1000)
        })
        .catch((err) => {
          state.isLoading = false
          const response = err.response.data
          state.serverErrors = response.errors ? response.errors : {}
          setResponseInfoBox("Error", response.message)
        })
    }

    const generatePIN = () => {
      axios.get("/admin/users/pin/get/").then((response) => {
        const data = response.data
        if (data) {
          state.form.pin = data.random_pin
        }
      })
    }

    const setFormByUserRecord = (user) => {
      if (user) {
        state.form.user_initials = user.user_initials
        state.form.first_name = user.first_name
        state.form.last_name = user.last_name
        state.form.pin = user.pin ? user.pin.pin : ""
        state.form.status = Object.prototype.hasOwnProperty.call(user, "status")
          ? user.status
          : state.form.status
      }
    }

    const cancelEdit = () => {
      store.commit("users/SET_EDITABLE_SUBSTITUTE", null)
      resetForm()
      setResponseInfoBox()
      emit("closeModal")
    }

    const resetForm = () => {
      if (state.isMounted) {
        v$.value.$reset()
      }
      state.form = {
        user_initials: "",
        first_name: "",
        last_name: "",
        pin: "",
        status: state.user_statuses[0]
      }
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    return {
      state,
      editableSubstitute,
      isEditMode,
      errors,
      submit,
      generatePIN,
      cancelEdit
    }
  }
}
</script>
