<template>
  <div class="v3-form periods-form">
    <div class="row">
      <div class="d-flex">
        <div class="flex-1">
          <Label required>Period name</Label>
          <InputField
            :invalid-feedback="errors.name || state.serverErrors.name"
            class="w-full"
            v-model="state.form.name"
          ></InputField>
        </div>
        <div class="ps-3">
          <div class="pb-1 mb-2"><Label required>Active</Label></div>
          <CustomSwitch v-model="state.form.status" />
        </div>
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel()" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="submit()"
          class="mt-4 px-4"
          solid
          rounded
          >{{ isEditMode ? "Update" : "Submit" }}</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import Label from "@/v3components/shared/Form/Label"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import InputField from "@/v3components/shared/Form/InputField"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, computed, onMounted } from "vue"

export default {
  name: "CreatePeriodForm",
  components: {
    Label,
    LoadingButton,
    InfoBox,
    InputField,
    CustomSwitch
  },
  props: {
    editableMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      serverErrors: {},
      serverRes: null,
      form: {
        name: null,
        status: true
      }
    })

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        name: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const editablePeriod = computed(
      () => store.getters["periods/editablePeriod"]
    )
    const isEditMode = computed(
      () => !!editablePeriod.value && props.editableMode
    )

    const submit = () => {
      if (isFormValid.value) {
        if (isEditMode.value) {
          updatePeriod()
        } else {
          createPeriod()
        }
      } else {
        v$.value.$touch()
      }
    }

    const createPeriod = () => {
      state.isProcessing = true
      store
        .dispatch("periods/createPeriod", state.form)
        .then(() => {
          store.dispatch("periods/getPeriods")
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          resetFormData()
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const updatePeriod = () => {
      state.isProcessing = true
      store
        .dispatch("periods/updatePeriod", {
          periodId: editablePeriod.value.id,
          data: { ...editablePeriod.value, ...state.form }
        })
        .then(() => {
          store.dispatch("periods/getPeriods")
          setResponseInfoBox("Success", "Successfully edited!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            resetFormData()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const resetFormData = () => {
      store.commit("periods/SET_EDITABLE_PERIOD", null)
      state.form.name = ""
      state.form.status = false
      v$.value.$reset()
    }

    onMounted(() => {
      if (isEditMode.value) {
        state.form = Object.assign(state.form, {
          name: editablePeriod.value.name,
          status: editablePeriod.value.status
        })
      }
    })

    return {
      state,
      submit,
      isFormValid,
      errors,
      onCancel,
      setResponseInfoBox,
      isEditMode
    }
  }
}
</script>
