<template>
  <div>
    <div v-if="pass && state.readyToRender" class="edit-pass-time-form p-4">
      <div class="pass-info">
        <div
          class="d-flex pb-3 align-items-center justify-space-between bottom-box-separator darken-separator"
        >
          <PassFlag class="me-2" :pass="pass" />
          <div class="user-info d-flex align-items-center flex-1">
            <div class="c-avatar-pass-history me-2">
              <img
                width="40"
                height="40"
                :src="
                  pass.user.avatar
                    ? pass.user.avatar
                    : 'img/avatars/user-avatar.jpeg'
                "
                class="c-avatar-pass-history-img"
              />
            </div>
            <div class="user-names">
              {{ pass.user.first_name }} {{ pass.user.last_name }}
            </div>
          </div>
          <PassStatus :hide-icon="true" :pass="pass" />
        </div>

        <div
          class="py-3 d-flex justify-space-between bottom-box-separator darken-separator"
        >
          <div class="pass-time-box d-flex justify-space-between flex-1 px-2">
            <span class="flex-1 section-label">OUT</span>
            <div class="text-end">
              <div>{{ pass.to?.name }}</div>
              <div
                :class="{ active: activeTimeType === activeTimeField.out }"
                class="pass-time d-inline-block"
              >
                {{ activeTimeField.outTime }}
              </div>
              <InOutColumn
                :editable-pass-time="false"
                :pass="pass"
                :section="activeTimeField.out"
              />
            </div>
          </div>
          <div
            class="pass-time-box d-flex justify-space-between flex-1 px-2 left-box-separator darken-separator"
          >
            <span class="flex-1 section-label">IN</span>
            <div class="text-end">
              <div>{{ pass.from?.name }}</div>
              <div
                :class="{ active: activeTimeType === activeTimeField.in }"
                class="pass-time d-inline-block"
              >
                {{ activeTimeField.inTime }}
              </div>
              <InOutColumn
                :editable-pass-time="false"
                :pass="pass"
                :section="activeTimeField.in"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pass-time-form mt-3">
        <Label>New {{ activeTimeField.label }}</Label>
        <CustomTimePicker
          format="HH:mm"
          v-if="activeTimeField"
          :invalid-feedback="state.serverErrors[activeTimeField.field]"
          v-model="state.form[activeTimeField.field]"
        ></CustomTimePicker>
        <div
          v-if="isUserAllowTardy"
          class="tardy-late-pass d-flex flex-column mt-2"
        >
          <p class="mb-1">Mark this pass as:</p>
          <TardyRadioGroup v-model="state.form.tardy_badge" />
        </div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-3"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
    </div>

    <div class="text-center">
      <LoadingButton
        :is-loading="state.isProcessing"
        @click="changePassHistoryTime()"
        class="mt-4 mb-2"
        solid
        rounded
        >Save</LoadingButton
      >
    </div>
  </div>
</template>
<script>
import TardyRadioGroup from "@/v3components/Forms/TardyRadioGroupOld"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import Label from "@/v3components/shared/Form/Label"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker"
import PassFlag from "@/v3components/shared/PassComponents/PassFlag.vue"
import PassStatus from "@/v3components/shared/PassComponents/PassStatus.vue"
import { computed, onMounted, reactive, watch } from "vue"
import { useStore } from "vuex"
import helpers from "@/helpers/index"

export default {
  name: "ChangePassHistoryTimeForm",
  props: {
    pass: {
      type: Object,
      required: true
    },
    activeTimeType: {
      type: String,
      required: true
    }
  },
  components: {
    TardyRadioGroup,
    PassFlag,
    PassStatus,
    LoadingButton,
    InfoBox,
    Label,
    CustomTimePicker
  },
  emits: ["passTimeChange"],
  setup(props, { emit }) {
    const state = reactive({
      form: {
        out_time: "",
        in_time: "",
        child_out_time: "",
        child_in_time: "",
        tardy_badge: ""
      },
      isProcessing: false,
      serverErrors: {},
      serverRes: null,
      readyToRender: false
    })
    const store = useStore()

    const tardyManagementOptions = computed(
      () => store.getters["schools/tardyManagementOptions"]
    )
    const currentUser = computed(() => store.getters["authentication/user"])

    const isUserAllowTardy = computed(() => {
      if (tardyManagementOptions.value != null) {
        if (tardyManagementOptions.value.usage["within-ehp"]) {
          if (currentUser.value.role.name == "admin") {
            return true
          } else if (
            (currentUser.value.role.name == "staff" ||
              currentUser.value.role.name == "teacher") &&
            tardyManagementOptions.value["allow-teacher-staff"]
          ) {
            return true
          } else if (
            currentUser.value.allow_tardy &&
            tardyManagementOptions.value["allow-selected-users-only"]
          ) {
            return true
          }
          return false
        }
        return false
      }
      return false
    })

    const setCurrentPassTime = (pass) => {
      if (pass) {
        state.form.out_time = pass.approved_at
          ? helpers.transformDate(pass.approved_at, "HH:mm")
          : state.form.out_time
        state.form.in_time = pass.completed_at
          ? helpers.transformDate(pass.completed_at, "HH:mm")
          : state.form.in_time
        state.form.tardy_badge = pass.tardy_badge
        if (pass.child) {
          state.form.child_out_time = pass.child.approved_at
            ? helpers.transformDate(pass.child.approved_at, "HH:mm")
            : state.form.child_out_time
          state.form.child_in_time = pass.child.completed_at
            ? helpers.transformDate(pass.child.completed_at, "HH:mm")
            : state.form.child_in_time
        }
      }
    }

    const activeTimeField = computed(() => {
      switch (props.activeTimeType) {
        case "approved_at":
          return {
            field: "out_time",
            label: "out time",
            out: "approved_at",
            in: "completed_at",
            outTime: helpers.transformDate(props.pass.approved_at, "h:mm A"),
            inTime: helpers.transformDate(props.pass.completed_at, "h:mm A")
          }
        case "completed_at":
          return {
            field: "in_time",
            label: "in time",

            out: "approved_at",
            in: "completed_at",

            outTime: helpers.transformDate(props.pass.approved_at, "h:mm A"),
            inTime: helpers.transformDate(props.pass.completed_at, "h:mm A")
          }
        case "child_approved_at":
          return {
            field: "child_out_time",
            label: "out time",
            out: "child_approved_at",
            in: "child_completed_at",

            outTime: props.pass.child.approved_at
              ? helpers.transformDate(props.pass.child.approved_at, "h:mm A")
              : null,
            inTime: props.pass.child.completed_at
              ? helpers.transformDate(props.pass.child.completed_at, "h:mm A")
              : null
          }
        case "child_completed_at":
          return {
            field: "child_in_time",
            label: "in time",
            out: "child_approved_at",
            in: "child_completed_at",

            outTime: props.pass.child.approved_at
              ? helpers.transformDate(props.pass.child.approved_at, "h:mm A")
              : null,
            inTime: props.pass.child.completed_at
              ? helpers.transformDate(props.pass.child.completed_at, "h:mm A")
              : null
          }
      }
      return null
    })

    const passStateTimePeriods = computed(() => {
      return props.pass.child
        ? [
            state.form.out_time || null,
            state.form.in_time || null,
            state.form.child_out_time || null,
            state.form.child_in_time || null
          ]
        : [state.form.out_time || null, state.form.in_time || null]
    })

    const changePassHistoryTime = () => {
      if (!validTimePeriods()) {
        setResponseInfoBox(
          "Incorrect time periods",
          `Out time must be before In Time${
            props.pass.child
              ? " and returning Pass times can't be before initial Pass times."
              : ""
          }`
        )
        return
      }
      state.isProcessing = true
      const data = {
        passID: props.pass.id,
        newTime: state.form
      }
      store
        .dispatch("passes/changePassHistoryTime", data)
        .then((response) => {
          const data = response.data.data
          setResponseInfoBox("Success", "Successfully updated!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            emit("passTimeChange", data)
          }, 1000)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors || {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const validTimePeriods = () =>
      !passStateTimePeriods.value.some(
        (periodValue, index) =>
          periodValue > passStateTimePeriods.value[index + 1]
      )

    watch(
      () => props.pass,
      (value) => {
        setTimeout(() => {
          if (value) {
            setCurrentPassTime(value)
          }
        }, 0)
      }
    )

    onMounted(() => {
      state.readyToRender = true
      setCurrentPassTime(props.pass)
    })

    return {
      state,
      changePassHistoryTime,
      isUserAllowTardy,
      activeTimeField,
      passStateTimePeriods
    }
  }
}
</script>

<style lang="scss"></style>
