<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start px-0">
        <CRow>
          <CCol md="3">
            <Label required> Pass limit </Label>
            <InputField
              input-type="number"
              :invalid-feedback="errors.pass_limit"
              v-model="state.form.pass_limit"
              :on-model-update="setPassLimit(state.form.pass_limit)"
            ></InputField>
          </CCol>
          <CCol md="9">
            <Label> Reason </Label>
            <InputField
              v-model="state.form.reason"
              :rows="1"
              class="w-full"
              type="textarea"
            />
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <Label required> Start Date </Label>
            <InputField
              v-if="state.startDateCheck"
              class="w-100"
              v-model="state.form.start_date"
              disabled
            />
            <CustomDatePicker
              v-else
              v-model="state.form.start_date"
              :masks="{ L: 'MM/DD/YYYY' }"
              :invalid-feedback="errors.start_date"
              class="cs-date-picker"
              :popover="{ visibility: 'click' }"
              :min-date="minFromDate"
              :max-date="maxDate"
              @update:model-value="onStartDateChange"
            >
            </CustomDatePicker>
          </CCol>
          <CCol>
            <Label required> End Date </Label>

            <CustomDatePicker
              v-model="state.form.end_date"
              :masks="{ L: 'MM/DD/YYYY' }"
              :invalid-feedback="errors.end_date"
              class="cs-date-picker"
              :popover="{ visibility: 'click' }"
              :min-date="minToDate"
              :max-date="maxDate"
            >
            </CustomDatePicker>
          </CCol>
        </CRow>

        <CRow class="mt-2">
          <CCol>
            <Label> Repeat </Label>
            <CustomSelect
              v-model="state.form.recurrence_type"
              :options="state.repeatOptions"
              :clearable="false"
              :close-on-select="true"
              label="label"
              :reduce="(item) => item.value"
            >
            </CustomSelect>
          </CCol>
        </CRow>

        <CRow class="mt-2">
          <CCol>
            <Label> Limitation for </Label>
            <InputField v-if="editableNames" v-model="editableNames" disabled />
            <CustomSelect
              v-else
              v-model="state.form.limit_for"
              :options="state.locationAvailabilityOptions"
              :clearable="false"
              :close-on-select="true"
              label="label"
              :reduce="(item) => item.value"
              @update:model-value="resetOptinalInputs"
            >
            </CustomSelect>
          </CCol>
        </CRow>

        <CRow class="mt-2" v-if="state.form.limit_for === 'student'"
          ><CCol>
            <SelectList
              select-placeholder="Select students"
              select-type="student"
              counter-icon="ri-group-line"
              :invalid-feedback="state.selected_student_ids"
              @update-list-data="(value) => updateData(value)"
            />
          </CCol>
        </CRow>

        <CRow
          class="mt-2"
          v-if="state.gradeYears && state.form.limit_for === 'gradeyear'"
        >
          <CCol>
            <CustomSelect
              :filterable="true"
              :deselect-from-dropdown="true"
              :invalid-feedback="state.grade_years"
              type="textarea"
              :multiple="true"
              v-model="state.form.grade_years"
              :options="state.gradeYears"
              placeholder="Select grad years"
            ></CustomSelect>
          </CCol>
        </CRow>

        <CRow class="mt-2" v-if="state.form.limit_for === 'csv'">
          <CCol>
            <FileUpload
              :uploaded-file="state.form.csv_file"
              :download-example-file-path="state.downloadExamplePath"
              @update:model-value="onFileUpload"
              @remove-file="removeFile"
              :invalid-feedback="
                errors.csv_file || state.reqResponse.errors['csv_file']
              "
            >
            </FileUpload>
          </CCol>
        </CRow>

        <CRow class="mt-2">
          <CCol>
            <CAlert
              v-if="state.reqResponse.message"
              class="mt-4 w-100"
              :color="state.reqResponse.type"
              :show="!!state.reqResponse.message"
            >
              {{ state.reqResponse.message }}
            </CAlert>
          </CCol>
        </CRow>

        <div class="d-flex align-items-center justify-content-center mt-4 text-center">
          <LoadingButton
            v-if="isInView"
            class="me-3 px-4"
            @click="cancelEdit"
            rounded
            >Cancel</LoadingButton
          >
          <LoadingButton
            @click="submit(props.editablePassLimit)"
            class="me-3 px-4"
            solid
            rounded
            >{{ props.editablePassLimit ? "Update" : "Create" }}</LoadingButton
          >
          <router-link
            v-if="!isInView"
            class="text-graident-blue"
            to="/limits/limit-student-pass"
          >
            <div class="d-flex align-items-center" @click="goToFullMenu()">
              <img
                width="18"
                class="m-0 me-2"
                src="@/assets/images/icons/gradient-link.png"
              />
              <span class="text-graident-blue">Go to full menu</span>
            </div>
          </router-link>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import moment from "moment-timezone"
import helpersJS from "../../helpers/index"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, computed, onMounted, inject } from "vue"
import Label from "@/v3components/shared/Form/Label"
import InputField from "@/v3components/shared/Form/InputField"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import FileUpload from "@/v3components/shared/Form/FileUpload"
import SelectList from "@/v3components/shared/Form/SelectList"

export default {
  name: "CreateLimitStudentPassForm",
  components: {
    Label,
    InputField,
    CustomDatePicker,
    CustomSelect,
    LoadingButton,
    FileUpload,
    SelectList
  },
  props: ["isInView", "editablePassLimit"],
  emits: ["CreatePassLimit", "closeModal", "cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      downloadExamplePath: "/csv-samples/passLimitSample.csv",
      isLoading: false,
      startDateCheck: false,
      form: {
        limit_for: null,
        csv_file: null,
        grade_years: null,
        selected_student_ids: null,
        start_date: null,
        end_date: null,
        pass_limit: null,
        reason: "",
        recurrence_type: null
      },
      locationAvailabilityOptions: [
        { value: "school", label: "All Students" },
        { value: "student", label: "Selected Students" },
        { value: "gradeyear", label: "Grad Year Students" },
        { value: "csv", label: "CSV Student List" }
      ],
      repeatOptions: [
        {
          label: "No Repetition",
          value: null
        },
        {
          label: "Daily",
          value: "daily"
        },
        {
          label: "Weekly",
          value: "weekly"
        },
        {
          label: "Monthly",
          value: "monthly"
        }
      ],
      weekDays: [
        {
          label: "Mo",
          value: "Monday"
        },
        {
          label: "Tu",
          value: "Tuesday"
        },
        {
          label: "We",
          value: "Wednesday"
        },
        {
          label: "Th",
          value: "Thursday"
        },
        {
          label: "Fr",
          value: "Friday"
        },
        {
          label: "Sa",
          value: "Saturday"
        },
        {
          label: "Su",
          value: "Sunday"
        }
      ],
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      gradeYears: []
    })

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      form: {
        limit_for: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        start_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        end_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        recurrence_type: {},
        pass_limit: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        reason: {},
        selected_student_ids: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return state.form.limit_for && state.form.limit_for === "student"
            })
          )
        },
        grade_years: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return (
                state.form.limit_for && state.form.limit_for === "gradeyear"
              )
            })
          )
        },
        csv_file: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return state.form.limit_for && state.form.limit_for === "csv"
            })
          )
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const minFromDate = computed(() => {
      return moment().format("MMM DD YYYY")
    })

    const maxDate = computed(() => {
      return helpersJS.maxSchoolYearDate()
    })

    const minToDate = computed(() => {
      return state.form.start_date
        ? state.form.start_date
        : moment().format("MMM DD YYYY")
    })

    const editableNames = computed(() => {
      if (props.editablePassLimit) {
        if (props.editablePassLimit.grade_year) {
          return (
            "Grad Year: " + JSON.parse(props.editablePassLimit.grade_year)[0]
          )
        }
        if (props.editablePassLimit.limitable) {
          switch (props.editablePassLimit.limitable_type) {
            case "App\\Models\\School":
              return "School: " + props.editablePassLimit.limitable.name

            case "App\\Models\\User":
              return (
                "Student: " +
                props.editablePassLimit.limitable.first_name +
                " " +
                props.editablePassLimit.limitable.last_name
              )

            default:
              break
          }
        }
      }
      return null
    })

    const isEditableStartDate = computed(() => {
      return moment(state.form.start_date)._d >= moment()._d
    })

    const isEditableMode = computed(() => {
      return !!props.editablePassLimit
    })

    onMounted(() => {
      setFormByPassRecord(props.editablePassLimit)
      getGradeYears()
      state.form.limit_for = state.locationAvailabilityOptions[0].value
    })

    const setFormByPassRecord = (passLimit) => {
      if (passLimit) {
        state.form = Object.assign(state.form, {
          limit_for: "student",
          csv_file: null,
          grade_years: null,
          selected_student_ids: null,
          start_date: passLimit.from_date ? passLimit.from_date : null,
          end_date: passLimit.to_date ? passLimit.to_date : null,
          pass_limit: passLimit.limit,
          reason: passLimit.reason,
          recurrence_type: passLimit.recurrence_type
        })
        state.startDateCheck = !isEditableStartDate.value
      }
    }

    const submit = (isUpdate) => {
      resetResponseMessages()
      if (isValid.value) {
        let formData = null
        const inputsData = {
          limit_for: state.form.limit_for,
          from_date: helpersJS.currTzDate(state.form.start_date),
          to_date: helpersJS.currTzDate(state.form.end_date),
          limit: state.form.pass_limit,
          reason: state.form.reason,
          recurrence_type: state.form.recurrence_type
        }
        if (state.form.selected_student_ids && !isUpdate) {
          inputsData.selected_student_ids = Array.isArray(
            state.form.selected_student_ids
          )
            ? JSON.stringify(
                state.form.selected_student_ids.map((el) => el.value.id)
              )
            : JSON.stringify(state.form.selected_student_ids)
        }
        if (state.form.grade_years) {
          inputsData.grade_years = JSON.stringify(state.form.grade_years)
        }
        if (state.form.limit_for === "csv") {
          inputsData.csv_file = state.form.csv_file
          formData = new FormData()
          for (const key in inputsData) {
            if (Object.hasOwnProperty.call(inputsData, key)) {
              const element = inputsData[key]
              formData.append(key, element)
            }
          }
        }
        if (isUpdate) {
          updatePassLimit(inputsData)
        } else {
          createPassLimit(formData ? formData : inputsData)
        }
      } else {
        v$.value.$touch()
      }
    }

    const createPassLimit = (params) => {
      state.isLoading = true
      store
        .dispatch("passLimits/createPassLimit", params)
        .then(() => {
          modal.setAction("createStudentPassLimit")
          setSuccessResponse("Successfully created.")
          state.isLoading = false
          setTimeout(() => {
            resetForm()
            resetResponseMessages()
            emit("CreatePassLimit")
            emit("cancel")
            emit("closeModal")
          }, 1000)
        })
        .catch((err) => {
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
          state.isLoading = false
        })
    }

    const updatePassLimit = (params) => {
      state.isLoading = true
      const data = {
        params,
        id: props.editablePassLimit.id
      }
      store
        .dispatch("passLimits/updatePassLimit", data)
        .then(() => {
          modal.setAction("createStudentPassLimit")
          state.startDateCheck = false
          resetForm()
          setSuccessResponse("Successfully Updated.")
          state.isLoading = false
          setTimeout(() => {
            cancelEdit()
            store.commit("passLimits/SET_EDITABLE_PASS_LIMITS", null)
          }, 1000)
        })
        .catch((err) => {
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
          state.isLoading = false
        })
    }

    const resetOptinalInputs = () => {
      state.form.csv_file = null
      state.form.grade_years = null
      state.form.selected_student_ids = null
    }

    const resetRecurrenceInputs = () => {
      state.form.recurrence_days = []
      state.form.recurrence_end_at = null
      state.form.recurrence_week = null
    }

    const onFileUpload = (event) => {
      const files = event.target.files
      if (files && files.length) {
        state.form.csv_file = files[0]
      }
      event.target.value = ""
    }

    const onStartDateChange = () => {
      state.form.end_date =
        state.form.end_date && state.form.end_date < state.form.start_date
          ? state.form.start_date
          : state.form.end_date
    }

    const onRepeatOptionChange = () => {
      resetRecurrenceInputs()
    }

    const setWeekDay = (day) => {
      if (state.form.recurrence_days.length) {
        if (state.form.recurrence_days.filter((el) => el === day).length) {
          state.form.recurrence_days = state.form.recurrence_days.filter(
            (el) => el !== day
          )
        } else {
          state.form.recurrence_days.push(day)
        }
      } else {
        state.form.recurrence_days.push(day)
      }
    }

    const setPassLimit = (value) => {
      const numbers = /^[0-9]+$/
      if (value) {
        if (numbers.test) {
          state.form.pass_limit = parseInt(value) > 0 ? parseInt(value) : 0
        } else {
          state.form.pass_limit = ""
        }
      }
    }

    const getGradeYears = () => {
      store.dispatch("passBlocks/getGradeYears").then((result) => {
        state.gradeYears = result.data.data
        // let startYear = result.data.data.startYear;
        // let endYear = result.data.data.endYear;
        // state.gradeYears = [...Array(endYear - startYear + 1).keys()]. map(i => i + startYear);
      })
    }

    const resetForm = () => {
      v$.value.$reset()
      state.form = {
        limit_for: null,
        csv_file: null,
        grade_years: null,
        selected_student_ids: null,
        start_date: null,
        end_date: null,
        pass_limit: null,
        reason: "",
        recurrence_type: null,
        recurrence_days: [],
        recurrence_end_at: null
      }
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }

    const cancelEdit = () => {
      store.commit("passLimits/SET_EDITABLE_PASS_LIMITS", null)
      state.startDateCheck = false
      resetForm()
      resetResponseMessages()
      emit("closeModal")
      emit("cancel")
    }

    const refreshForm = () => {
      resetForm()
      resetResponseMessages()
    }

    const goToFullMenu = () => {
      emit("closeModal")
    }

    const removeFile = () => {
      state.form.csv_file = null
    }

    const updateData = (value) => {
      state.form.selected_student_ids = value
    }

    return {
      state,
      props,
      isValid,
      minFromDate,
      maxDate,
      minToDate,
      editableNames,
      isEditableStartDate,
      isEditableMode,
      setFormByPassRecord,
      submit,
      createPassLimit,
      resetOptinalInputs,
      resetRecurrenceInputs,
      onFileUpload,
      onStartDateChange,
      onRepeatOptionChange,
      setWeekDay,
      setPassLimit,
      getGradeYears,
      resetForm,
      cancelEdit,
      refreshForm,
      goToFullMenu,
      errors,
      removeFile,
      updateData
    }
  }
}
</script>

<style>
.v3-input-field {
  width: 100%;
}
</style>
