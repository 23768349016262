<template>
  <div class="v3-form polarity-message-form">
    <div class="row">
      <div>
        <Label>Message</Label>
        <InputField
          type="textarea"
          v-model="state.form.message"
          class="d-block"
          :rows="5"
        ></InputField>
      </div>
      <div>
        <InfoBox class="mt-4"
          >Message all Polarity Students will see when they try to create a pass
          while their polarity person is already out on a pass.</InfoBox
        >
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="submit()"
          class="mt-4 px-4"
          solid
          rounded
          >Save</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import Label from "@/v3components/shared/Form/Label"
import InputField from "@/v3components/shared/Form/InputField"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"

export default {
  name: "ContactControlMessageForm",
  components: {
    Label,
    InputField,
    InfoBox,
    LoadingButton
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      form: {
        message: ""
      },
      serverErrors: {},
      serverRes: null
    })

    const polarityMessage = computed(
      () => store.getters["polarities/polarityMessage"]
    )

    onMounted(() => {
      if (!polarityMessage.value) {
        store.dispatch("polarities/getPolarityMessage").then((response) => {
          const data = response.data.data
          if (data && Object.prototype.hasOwnProperty.call(data, "message")) {
            store.commit("polarities/SET_POLARITY_MESSAGE", data.message)
            state.form.message = data.message
              ? data.message
              : data.initial_default_value
          }
        })
      }
      state.form.message = polarityMessage.value
    })

    const submit = () => {
      const data = {
        message: state.form.message
      }
      savePolarityMessage(data)
    }

    const savePolarityMessage = (data) => {
      state.isProcessing = true
      store
        .dispatch("polarities/createPolarityMessage", data)
        .then((response) => {
          const data = response.data.data
          if (data && Object.prototype.hasOwnProperty.call(data, "message")) {
            store.commit("polarities/SET_POLARITY_MESSAGE", data.message)
          }
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      emit("cancel")
    }

    return {
      state,
      submit,
      setResponseInfoBox,
      onCancel
    }
  }
}
</script>
