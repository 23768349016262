<template>
  <div class="v3-form polarity-message-form">
    <div class="row">
      <div class="col">
        <Label required>Start date</Label>
        <CustomDatePicker
          v-model="state.form.from_date"
          :masks="{ L: 'MM/DD/YYYY' }"
          :invalid-feedback="errors.from_date"
          input-class="d-block"
          placeholder="Today"
          :popover="{ visibility: 'click' }"
          :min-date="!editablePassBlockData ? today : state.form.from_date"
          :max-date="maxDate"
          :disabled="Boolean(editablePassBlockData)"
          @update:model-value="onStartDateChange"
        >
        </CustomDatePicker>
      </div>
      <div class="col">
        <Label required>End date</Label>
        <CustomDatePicker
          v-model="state.form.to_date"
          :masks="{ L: 'MM/DD/YYYY' }"
          :invalid-feedback="errors.to_date"
          input-class="d-block"
          placeholder="Today"
          :popover="{ visibility: 'click' }"
          :min-date="
            state.form.from_date && !editablePassBlockData
              ? state.form.from_date
              : today
          "
          :max-date="maxDate"
        >
        </CustomDatePicker>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <Label required>Start time</Label>
        <CustomTimePicker
          format="hh:mm aa"
          v-model="state.form.from_time"
          :invalid-feedback="errors.from_time"
          inner-label="Start Time:"
          :readonly="Boolean(!isEditableFromDate)"
        >
        </CustomTimePicker>
      </div>
      <div class="col">
        <Label required>End time</Label>
        <CustomTimePicker
          format="hh:mm aa"
          v-model="state.form.to_time"
          :invalid-feedback="errors.to_time"
          inner-label="End Time:"
        >
        </CustomTimePicker>
      </div>
    </div>
    <div class="mt-3">
      <Label>Repeat</Label>
      <CustomSelect
        v-model="state.form.recurrence_type"
        :options="recurrenceOptions"
        :invalid-feedback="errors.recurrence_days"
        :clearable="false"
        :close-on-select="true"
        :reduce="(item) => item.value"
        @update:model-value="resetRecurrenceOptions"
      >
        <template #content>
          <CRow>
            <CCol
              v-if="state.form.recurrence_type === 'WEEKLY'"
              sm="12"
              class="pe-3 pe-md-2"
            >
              <div
                class="v3weekdays-options-container pt-0 pt-sm-2 pb-sm-2 d-flex justify-content-between"
              >
                <div
                  class="d-flex flex-row ms-2 me-2"
                  v-for="(day, index) in weekDays"
                  :key="index"
                >
                  <div
                    class="v3weekday-box"
                    :class="{
                      active: state.form.recurrence_days.find(
                        (el) => el === day.value
                      )
                    }"
                    @click="setWeekDay(day.value)"
                  >
                    <i
                      v-if="state.form.recurrence_days.includes(day.value)"
                      class="ri-check-line check-icon"
                    ></i>
                  </div>
                  <div class="ms-1">{{ day.label }}</div>
                </div>
              </div>
            </CCol>
          </CRow>
        </template>
      </CustomSelect>
    </div>
    <div class="mt-3">
      <Label>Pass types, included in state blocking period</Label>
      <div class="row mt-2" style="font-size: 13px">
        <div class="col d-flex flex-column">
          <CustomCheck
            label="Student Created"
            v-model="state.form.students"
            :checked="!!state.form.students"
            class="me-2"
          ></CustomCheck>
          <CustomCheck
            label="Kiosk"
            v-model="state.form.kiosk"
            :checked="!!state.form.kiosk"
            class="me-2 mt-2"
          ></CustomCheck>
        </div>
        <div class="col d-flex flex-column">
          <CustomCheck
            label="Teacher Pass (Proxy Pass)"
            v-model="state.form.proxy"
            :checked="!!state.form.proxy"
            class="me-2"
          ></CustomCheck>
          <CustomCheck
            label="Appointment Pass"
            v-model="state.form.appointments"
            :checked="!!state.form.appointments"
            class="me-2 mt-2"
          ></CustomCheck>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <Label
        >Reason for blocking
        <span class="opacity-75">(shown to students and teachers)</span></Label
      >
      <InputField
        data-test-id="pass-block-modal-admin-reason"
        type="textarea"
        v-model="state.form.reason"
        class="d-block"
        :rows="4"
        :invalid-feedback="errors.reason"
      ></InputField>
    </div>
    <div class="mt-3">
      <Label>Message to students who are out on pass</Label>
      <InputField
        data-test-id="pass-block-modal-student-reason"
        type="textarea"
        v-model="state.form.message"
        class="d-block"
        :rows="4"
        :invalid-feedback="errors.message"
      ></InputField>
    </div>
    <div>
      <InfoBox
        data-test-id="pass-block-modal-info-box"
        v-if="state.serverRes"
        class="mt-4"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>
    <div
      class="d-flex align-items-center justify-content-center mt-4 text-center"
    >
      <LoadingButton v-if="!isHeaderCreateButton" @click="onCancel" rounded
        >Cancel</LoadingButton
      >
      <LoadingButton
        data-test-id="pass-block-modal-submit-button"
        :is-loading="state.isProcessing"
        @click="editablePassBlockData ? submit(true) : submit()"
        class="mx-2"
        solid
        rounded
        >{{ editablePassBlockData ? "Update" : "Create" }}</LoadingButton
      >
      <router-link
        v-if="isHeaderCreateButton"
        class="text-graident-blue d-flex flex-row-reverse"
        to="/limits/pass-blocking"
      >
        <div class="d-flex align-items-center" @click="onCancel">
          <img
            width="18"
            class="m-0 me-2"
            src="@/assets/images/icons/gradient-link.png"
          />
          <span class="text-graident-blue"
            ><span class="text-graident-blue">Go to full menu</span></span
          >
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, inject } from "vue"
import { useStore } from "vuex"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import moment from "moment-timezone"
import helpersJS from "@/helpers/index"
import Label from "@/v3components/shared/Form/Label"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import CustomCheck from "@/v3components/shared/Form/CustomCheck"
import InputField from "@/v3components/shared/Form/InputField"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"

export default {
  name: "CreatePassBlockingForm",
  components: {
    Label,
    CustomDatePicker,
    CustomTimePicker,
    CustomSelect,
    CustomCheck,
    InputField,
    InfoBox,
    LoadingButton
  },
  props: {
    isHeaderCreateButton: {
      type: Boolean,
      default: false
    },
    editablePassBlockData: {
      type: Object
    }
  },
  emits: ["cancel", "closeModal"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      isProcessing: false,
      form: {
        from_date: moment().format("MMM DD YYYY HH:mm:ss"),
        to_date: moment().format("MMM DD YYYY HH:mm:ss"),
        from_time: moment().format("hh:mm A"),
        to_time: moment().add(1, "hour").format("hh:mm A"),
        recurrence_type: null,
        recurrence_days: [],
        students: true,
        proxy: true,
        kiosk: true,
        appointments: true,
        reason: "",
        message: "",
        isEditableFromDate: true
      },
      serverErrors: {},
      serverRes: null
    })

    const recurrenceOptions = [
      {
        label: "No Repetition",
        value: null
      },
      {
        label: "Daily",
        value: "DAILY"
      },
      {
        label: "Weekly",
        value: "WEEKLY"
      }
    ]

    const weekDays = [
      {
        label: "Mo",
        value: "Monday"
      },
      {
        label: "Tu",
        value: "Tuesday"
      },
      {
        label: "We",
        value: "Wednesday"
      },
      {
        label: "Th",
        value: "Thursday"
      },
      {
        label: "Fr",
        value: "Friday"
      },
      {
        label: "Sa",
        value: "Saturday"
      },
      {
        label: "Su",
        value: "Sunday"
      }
    ]

    const isEditableFromDate = computed(() => {
      if (props.editablePassBlockData) {
        if (state.form.recurrence_type == null) {
          return props.editablePassBlockData
            ? props.editablePassBlockData.from_date &&
                helpersJS.transformDate(
                  props.editablePassBlockData.from_date,
                  "YYYY-MM-DD HH:mm:ss"
                ) >= helpersJS.date().format("YYYY-MM-DD HH:mm:ss")
            : true
        } else {
          if (
            moment(helpersJS.date().format("MM/DD/YYYY")).isSame(
              moment(
                helpersJS.currTzDate(props.editablePassBlockData.from_date)
              )
            )
          ) {
            return false
          }
          if (
            moment(
              helpersJS.currTzDate(props.editablePassBlockData.from_date)
            ).isAfter(moment(helpersJS.date().format("MM/DD/YYYY")))
          ) {
            return true
          }
          return true
        }
      } else {
        return true
      }
    })

    const today = computed(() => moment().format("MMM DD YYYY HH:mm"))

    const maxDate = computed(() => helpersJS.maxSchoolYearDate())

    const validationMessages = {
      required: "This field is required",
      startTimeValue: "Start time can not be in the past",
      endTimeValue: "End time must be greater than start time"
    }
    const validations = {
      form: {
        from_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        to_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        from_time: {
          required: helpers.withMessage(validationMessages.required, required),
          startTimeValue: helpers.withMessage(
            validationMessages.startTimeValue,
            () => {
              const nowTime = helpersJS.date().format("hh:mm A")
              if (state.form.recurrence_type == null) {
                if (isEditableFromDate.value) {
                  if (
                    moment(helpersJS.currTzDate(state.form.from_date)).isSame(
                      moment(helpersJS.date().format("MM/DD/YYYY"))
                    )
                  ) {
                    return moment(
                      state.form.from_time,
                      "hh:mm A"
                    ).isSameOrAfter(moment(nowTime, "hh:mm A"))
                  } else {
                    return true
                  }
                } else {
                  return true
                }
              } else {
                if (props.editablePassBlockData) {
                  // If today is equale to from_date slot
                  if (
                    moment(helpersJS.date().format("MM/DD/YYYY")).isSame(
                      moment(
                        helpersJS.currTzDate(
                          props.editablePassBlockData.from_date
                        )
                      )
                    )
                  ) {
                    if (isEditableFromDate.value) {
                      return moment(
                        state.form.from_time,
                        "hh:mm A"
                      ).isSameOrAfter(moment(nowTime, "hh:mm A"))
                    } else {
                      return true
                    }
                  }
                  // If from_date slot is greater than today
                  if (
                    moment(
                      helpersJS.currTzDate(
                        props.editablePassBlockData.from_date
                      )
                    ).isAfter(moment(helpersJS.date().format("MM/DD/YYYY")))
                  ) {
                    return true
                  }
                } else {
                  // If today is equale to from_date field
                  if (
                    moment(helpersJS.date().format("MM/DD/YYYY")).isSame(
                      moment(helpersJS.currTzDate(state.form.from_date))
                    )
                  ) {
                    if (isEditableFromDate.value) {
                      return moment(
                        state.form.from_time,
                        "hh:mm A"
                      ).isSameOrAfter(moment(nowTime, "hh:mm A"))
                    } else {
                      return true
                    }
                  }
                  // If from_date field is greater than today
                  if (
                    moment(helpersJS.currTzDate(state.form.from_date)).isAfter(
                      moment(helpersJS.date().format("MM/DD/YYYY"))
                    )
                  ) {
                    return true
                  }
                }
              }
            }
          )
        },
        to_time: {
          required: helpers.withMessage(validationMessages.required, required),
          endTimeValue: helpers.withMessage(
            validationMessages.endTimeValue,
            (value) => {
              return moment(value, "hh:mm A").isAfter(
                moment(state.form.from_time, "hh:mm A")
              )
            }
          )
        },
        recurrence_days: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return (
                state.form.recurrence_type &&
                state.form.recurrence_type == "WEEKLY"
              )
            })
          )
        },
        reason: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        message: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      if (props.editablePassBlockData) {
        setFormByPassRecord(props.editablePassBlockData)
      }
    })

    const setFormByPassRecord = (passBlockData) => {
      const passBlock = passBlockData
        ? JSON.parse(JSON.stringify(passBlockData))
        : null
      state.form = Object.assign(state.form, {
        from_date:
          passBlock.recurrence_type == null
            ? moment(passBlock.from_date)._d
            : helpersJS.currTzDate(passBlock.recurrence_start_at),
        to_date:
          passBlock.recurrence_type == null
            ? moment(passBlock.to_date)._d
            : moment(passBlock.recurrence_end_at)._d,
        from_time:
          passBlock.from_date == null && passBlock.recurrence_start_at
            ? moment(passBlock.recurrence_start_at).format("hh:mm A")
            : moment(passBlock.from_date).format("hh:mm A"),
        to_time:
          passBlock.to_date == null && passBlock.recurrence_end_at
            ? moment(passBlock.recurrence_end_at).format("hh:mm A")
            : moment(passBlock.to_date).format("hh:mm A"),
        recurrence_type: passBlock.recurrence_type,
        recurrence_days: passBlock.recurrence_days
          ? passBlock.recurrence_days.days
          : null,
        students: !!passBlock.students,
        kiosk: !!passBlock.kiosk,
        proxy: !!passBlock.proxy,
        appointments: !!passBlock.appointments,
        reason: passBlock.reason,
        message: passBlock.message
      })
    }

    const submit = (isUpdate) => {
      if (isFormValid.value) {
        const data = {
          from_date: helpersJS.currTzDateTime(
            state.form.from_date,
            moment(state.form.from_time, "hh:mm A").format("HH:mm")
          ),
          to_date: helpersJS.currTzDateTime(
            state.form.to_date,
            moment(state.form.to_time, "hh:mm A").format("HH:mm")
          ),
          students: state.form.students,
          kiosk: state.form.kiosk,
          proxy: state.form.proxy,
          appointments: state.form.appointments,
          recurrence_type: state.form.recurrence_type,
          recurrence_days: state.form.recurrence_days
            ? JSON.stringify({ days: state.form.recurrence_days })
            : JSON.stringify({ days: [] }),
          reason: state.form.reason,
          message: state.form.message
        }
        if (isUpdate && props.editablePassBlockData) {
          const dataObj = {
            id: props.editablePassBlockData.id,
            data
          }
          updatePassBlock(dataObj)
        } else {
          createPassBlock(data)
        }
      } else {
        v$.value.$touch()
      }
    }

    const createPassBlock = (data) => {
      state.isProcessing = true
      store
        .dispatch("passBlocks/createPassBlock", data)
        .then(() => {
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          modal.setAction("createPassBlock")
          setTimeout(() => {
            store.dispatch("passBlocks/getPassBlocks").then((response) => {
              const data = response.data
              if (data) {
                store.commit("passBlocks/SET_PASS_BLOCKS", data.data)
              }
            })
            setResponseInfoBox()
            emit("cancel")
            emit("closeModal")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.message ? res.message : {}
          setResponseInfoBox(res.status ? res.status : "Error", res.message)
          state.isProcessing = false
        })
    }

    const updatePassBlock = (data) => {
      state.isProcessing = true
      store
        .dispatch("passBlocks/updatePassBlock", data)
        .then(() => {
          setResponseInfoBox("Success", "Successfully updated!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            store.dispatch("passBlocks/getPassBlocks").then((response) => {
              const data = response.data
              if (data) {
                store.commit("passBlocks/SET_PASS_BLOCKS", data.data)
              }
            })
            setResponseInfoBox()
            emit("cancel")
            emit("closeModal")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.message ? res.message : {}
          setResponseInfoBox(res.status ? res.status : "Error", res.message)
          state.isProcessing = false
        })
    }

    const onStartDateChange = () => {
      const toDate = moment(state.form.to_date).format("MMM DD YYYY")
      const fromDate = moment(state.form.from_date).format("MMM DD YYYY")
      state.form.to_date =
        state.form.to_date && moment(toDate).isSameOrBefore(fromDate, "day")
          ? state.form.from_date
          : state.form.to_date
    }

    const setWeekDay = (day) => {
      if (state.form.recurrence_days && state.form.recurrence_days.length) {
        if (state.form.recurrence_days.filter((el) => el === day).length) {
          state.form.recurrence_days = state.form.recurrence_days.filter(
            (el) => el !== day
          )
        } else {
          state.form.recurrence_days.push(day)
        }
      } else {
        state.form.recurrence_days.push(day)
      }
    }

    const resetRecurrenceOptions = () => {
      state.form.recurrence_days = []
      state.form.recurrence_week = null
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
      emit("closeModal")
    }

    const resetFormData = () => {
      state.form = {
        from_date: moment().format("MMM DD YYYY HH:mm:ss"),
        to_date: moment().format("MMM DD YYYY HH:mm:ss"),
        from_time: moment().format("HH:mm"),
        to_time: moment().add(1, "hour").format("HH:mm"),
        recurrence_type: null,
        recurrence_days: [],
        kiosk: true,
        students: true,
        proxy: true,
        appointments: true,
        reason: "",
        message: ""
      }
    }

    return {
      state,
      recurrenceOptions,
      weekDays,
      isEditableFromDate,
      today,
      maxDate,
      v$,
      isFormValid,
      errors,
      submit,
      onStartDateChange,
      setWeekDay,
      resetRecurrenceOptions,
      setResponseInfoBox,
      onCancel,
      resetFormData
    }
  }
}
</script>
