<template>
  <div
    data-test-id="admin-create-apt-pass-form"
    class="v3-form appointmets-form"
  >
    <div class="mt-3">
      <Label required>Student</Label>
      <LazyLoadSelect
        data-test-id="admin-apt-pass-fromid"
        :is-multiple="false"
        :selected="state.form.user_id"
        type="student"
        placeholder="Select one"
        :disabled="editablePass && editablePass.has_appointment_detention"
        @changed="
          (value) => {
            state.form.user_id = value
            checkForExistApp()
          }
        "
        :invalid-feedback="errors.user_id"
      ></LazyLoadSelect>
    </div>
    <div class="mt-3">
      <Label required>Reason</Label>
      <InputField
        data-test-id="admin-apt-pass-comment"
        type="textarea"
        v-model="state.form.reason"
        class="d-block"
        :rows="4"
        max-chars="250"
        :invalid-feedback="errors.reason"
      ></InputField>
    </div>
    <div class="mt-3">
      <Label required>From</Label>
      <LazyLoadSelect
        :is-multiple="false"
        :selected="state.form.from_id"
        type="combined"
        placeholder="From"
        :default="defaultFrom"
        :prepend-items="[defaultFrom, { label: 'From: Me', value: meValue }]"
        :exclude-options="[{ label: 'From: Me', value: meValue }]"
        @changed="
          (value) => {
            state.form.from_id = value
          }
        "
        :invalid-feedback="errors.from_id"
      ></LazyLoadSelect>
    </div>
    <div class="mt-3">
      <Label required>To</Label>
      <LazyLoadSelect
        :is-multiple="false"
        :selected="state.form.to_id"
        type="combined"
        placeholder="To"
        :default="defaultTo"
        :prepend-items="[defaultTo]"
        :exclude-options="[{ value: meValue }]"
        @changed="
          (value) => {
            state.form.to_id = value
          }
        "
        :invalid-feedback="errors.to_id"
      ></LazyLoadSelect>
    </div>

    <div class="row mt-3">
      <div class="col">
        <Label required>Date</Label>
        <CustomDatePicker
          v-model="state.form.for_date"
          :masks="{ L: 'MM/DD/YYYY' }"
          input-class="d-block"
          placeholder="Today"
          :min-date="minDate"
          :max-date="maxDate"
          @update:model-value="
            formatMontlyWeekDay(state.form.for_date),
              checkForExistApp(),
              checkForRecurrenceEndTime()
          "
          :invalid-feedback="errors.for_date"
        >
        </CustomDatePicker>
      </div>
      <div class="col">
        <Label required>Time</Label>
        <CustomTimePicker
          format="HH:mm"
          v-model="state.form.time"
          placeholder="Now"
          @update:model-value="checkForExistApp()"
          @input="checkForExistApp()"
          :invalid-feedback="errors.time"
        >
        </CustomTimePicker>
      </div>
      <div class="col">
        <Label required>Period</Label>
        <CustomSelect
          data-test-id="admin-apt-pass-period"
          v-if="periods"
          v-model="state.form.period_id"
          :options="periods"
          placeholder="Select one"
          :clearable="false"
          :filterable="true"
          :close-on-select="true"
          :invalid-feedback="errors.period_id"
        ></CustomSelect>
      </div>
    </div>

    <div>
      <InfoBox
        v-if="state.appointment_exists_within_15_mins"
        class="mt-2"
        title="Warning"
        >This student has an appointment within 15 mins of the time you
        chose.</InfoBox
      >
    </div>

    <div class="mt-3">
      <Label>Repeat</Label>
      <CustomSelect
        v-model="state.form.recurrence_type"
        :options="recurrenceOptions"
        :clearable="false"
        :close-on-select="true"
        :reduce="(item) => item.value"
        @update:model-value="resetRecurrenceOptions"
        :invalid-feedback="errors.recurrence_days"
        :disabled="editablePass && true"
      >
        <template #content>
          <CRow>
            <CCol
              v-if="state.form.recurrence_type == 'weekly'"
              sm="12"
              class="pe-3 pe-md-2"
            >
              <div
                class="v3weekdays-options-container pt-0 pt-sm-2 pb-sm-2 d-flex justify-content-between"
              >
                <div
                  class="d-flex flex-row ms-2 me-2"
                  v-for="(day, index) in weekDays"
                  :key="index"
                >
                  <div
                    class="v3weekday-box"
                    :class="{
                      active: state.form.recurrence_days.find(
                        (el) => el === day.value
                      )
                    }"
                    @click="setWeekDay(day.value)"
                  >
                    <i
                      v-if="state.form.recurrence_days.includes(day.value)"
                      class="ri-check-line check-icon"
                    ></i>
                  </div>
                  <div class="ms-1">{{ day.label }}</div>
                </div>
              </div>
            </CCol>
          </CRow>
        </template>
      </CustomSelect>
    </div>

    <div v-if="!!state.form.recurrence_type" class="mt-3">
      <Label required>End on</Label>
      <CustomDatePicker
        v-model="state.form.recurrence_end_at"
        :masks="{ L: 'MM/DD/YYYY' }"
        placeholder="Date"
        input-class="d-block"
        :min-date="minDateforRecurrenceEndAt"
        :max-date="maxDate"
        :disabled-dates="
          state.form.recurrence_type === 'daily' ? [1, 7] : false
        "
        :invalid-feedback="errors.recurrence_end_at"
      ></CustomDatePicker>
    </div>
    <div>
      <InfoBox
        v-if="state.serverRes"
        class="mt-4"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>

    <div
      v-if="editablePass"
      class="d-flex align-items-center justify-content-center my-4"
    >
      <LoadingButton class="me-2 px-4" @click="onCancel()" rounded
        >Cancel</LoadingButton
      >
      <LoadingButton
        :is-loading="state.isProcessing"
        class="mx-2"
        solid
        rounded
        @click="submit()"
        >Update
      </LoadingButton>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, inject } from "vue"
import { useStore } from "vuex"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import moment from "moment-timezone"
import helpersJS from "../../helpers/index"
import Label from "@/v3components/shared/Form/Label"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import InputField from "@/v3components/shared/Form/InputField"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"

export default {
  name: "CreateAppoinmentsPassForm",
  components: {
    Label,
    LazyLoadSelect,
    CustomDatePicker,
    CustomTimePicker,
    CustomSelect,
    InputField,
    InfoBox,
    LoadingButton
  },
  props: {
    editablePass: {
      type: Object
    }
  },
  emits: ["cancel", "closeModal"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      isProcessing: false,
      form: {
        user_id: null,
        from_id: null,
        to_id: null,
        reason: "",
        for_date: null,
        period_id: null,
        time: null,
        recurrence_type: null,
        recurrence_days: [],
        recurrence_end_at: null,
        child_id: null
      },
      appointment_exists_within_15_mins: false,
      timeoutForExistApp: null,
      serverErrors: {},
      serverRes: null
    })

    const recurrenceOptions = [
      {
        label: "No Repetition",
        value: null
      },
      {
        label: "Daily (M-F)",
        value: "daily"
      },
      {
        label: "Weekly",
        value: "weekly"
      },
      {
        label: "Monthly",
        value: "monthly"
      }
    ]

    const weekDays = [
      {
        label: "Mo",
        value: "Monday"
      },
      {
        label: "Tu",
        value: "Tuesday"
      },
      {
        label: "We",
        value: "Wednesday"
      },
      {
        label: "Th",
        value: "Thursday"
      },
      {
        label: "Fr",
        value: "Friday"
      },
      {
        label: "Sa",
        value: "Saturday"
      },
      {
        label: "Su",
        value: "Sunday"
      }
    ]

    const currentUser = computed(() => store.getters["authentication/user"])
    const activeTab = computed(() => store.getters["adultAptPass/activeTab"])
    const periods = computed(() => store.getters["periods/formListStudents"])

    const meValue = computed(() => {
      return {
        id: currentUser.value.id,
        type: "App\\Models\\User",
        passesToday: currentUser.value.passes_for_today_count
          ? currentUser.value.passes_for_today_count
          : null
      }
    })

    const defaultTo = computed(() => {
      return {
        value: meValue.value,
        label: "To: Me"
      }
    })

    const defaultFrom = computed(() => {
      return {
        value: {
          id: 0,
          type: "App\\Models\\User",
          passesToday: null
        },
        label: "From: System"
      }
    })

    const today = computed(() => {
      return moment().format("MM/DD/YYYY HH:mm")
    })

    const minDate = computed(() => {
      return editablePassDate.value && editablePassDate.value < today.value
        ? editablePassDate.value
        : today.value
    })

    const maxDate = computed(() => {
      return helpersJS.maxSchoolYearDate()
    })

    const minDateforRecurrenceEndAt = computed(() => {
      return moment(state.form.for_date).format("MM/DD/YYYY") >
        moment().format("MM/DD/YYYY")
        ? moment(state.form.for_date).add(1, "days")._d
        : moment().add(1, "days")._d
    })

    const editablePassDate = computed(() => {
      if (
        activeTab.value === "nextSevenDays" &&
        props.editablePass &&
        props.editablePass.recurrence_appointment_pass
      ) {
        return moment(
          props.editablePass.recurrence_appointment_pass.for_date
        ).format("MM/DD/YYYY HH:mm")
      }
      return props.editablePass
        ? moment(props.editablePass.for_date).format("MM/DD/YYYY HH:mm")
        : null
    })

    const isForDateEditable = computed(() => {
      if (
        activeTab.value === "nextSevenDays" &&
        props.editablePass &&
        props.editablePass.recurrence_appointment_pass
      ) {
        return (
          editablePassDate.value &&
          moment(
            props.editablePass.recurrence_appointment_pass.for_date
          ).format("MM-DD-YYYY") >= moment().format("MM-DD-YYYY")
        )
      }
      return (
        editablePassDate.value &&
        moment(props.editablePass.for_date).format("MM-DD-YYYY") >=
          moment().format("MM-DD-YYYY")
      )
    })

    const formatedForDate = computed(() => {
      return moment(state.form.for_date).format("MM/DD/YYYY")
    })

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        user_id: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        from_id: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        to_id: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        reason: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        for_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        period_id: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        time: {},
        recurrence_days: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return (
                state.form.recurrence_type &&
                state.form.recurrence_type == "weekly"
              )
            })
          )
        },
        recurrence_end_at: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return state.form.recurrence_type !== null
            })
          )
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      if (props.editablePass) {
        setFormByPassRecord(props.editablePass)
      }
      state.form.for_date = moment().format("MM/DD/YYYY HH:mm")
      formatMontlyWeekDay(state.form.for_date)
    })

    const setFormData = (form) => {
      if (form) {
        for (const key in form) {
          if (Object.hasOwnProperty.call(form, key)) {
            const value = form[key]
            if (value && key !== "user_id") {
              state.form[key] = value
            }
          }
        }
      }
    }

    const formatMontlyWeekDay = (date) => {
      if (date) {
        const dayOfMonth = moment.utc(date).format("DD")
        const dayOfweek = moment.utc(date).format("dddd")
        const numberofweek = Math.floor((dayOfMonth - 1) / 7) + 1
        let prefix = ""
        switch (numberofweek) {
          case 1:
            prefix = "first"
            break
          case 2:
            prefix = "second"
            break
          case 3:
            prefix = "third"
            break
          case 4:
            prefix = "fourth"
            break
          default:
            prefix = "last"
        }
        state.form.recurrence_week =
          '{"' + numberofweek + '":"' + dayOfweek + '"}'
        recurrenceOptions[3].label =
          "Monthly on the " + prefix + " " + dayOfweek
      }
    }

    const setFormByPassRecord = (pass) => {
      if (pass) {
        let isDefaultFrom
        if (pass.from == null) {
          isDefaultFrom = true
        } else {
          isDefaultFrom =
            defaultFrom.value && defaultFrom.value.id === pass.from.id
        }
        state.form.from_id = isDefaultFrom
          ? defaultFrom
          : {
              label: pass.from.name,
              value: {
                id: pass.from.id,
                type: pass.from_type
              }
            }

        const isDefaultTo = defaultTo.value && defaultTo.value.id === pass.to.id
        state.form.to_id = isDefaultTo
          ? defaultTo.value
          : {
              label: pass.to.name,
              value: {
                id: pass.to.id,
                type: pass.to_type
              }
            }

        state.form.for_date = pass.for_date
          ? moment(pass.for_date).format("MM/DD/YYYY HH:mm")
          : null
        state.form.time = pass.for_date
          ? helpersJS.transformDate(pass.for_date, "HH:mm")
          : ""
        if (pass.latest_comment) {
          state.form.reason = pass.latest_comment.comment
        }
        state.form.user_id = {
          label: pass.user.first_name + " " + pass.user.last_name,
          value: {
            id: pass.user.id,
            type: "App\\Models\\User"
          }
        }
        state.form.period_id = pass.period
          ? {
              label: pass.period.name,
              value: pass.period.id
            }
          : null
        if (pass.recurrence_appointment_pass) {
          if (activeTab.value === "nextSevenDays") {
            state.form.for_date = pass.recurrence_appointment_pass.for_date
              ? moment(pass.recurrence_appointment_pass.for_date).format(
                  "MM/DD/YYYY HH:mm"
                )
              : ""
            state.form.child_id = pass.id
          }
          state.form.recurrence_type =
            pass.recurrence_appointment_pass.recurrence_type
          state.form.recurrence_days = pass.recurrence_appointment_pass
            .recurrence_days
            ? JSON.parse(pass.recurrence_appointment_pass.recurrence_days).days
            : null
          state.form.recurrence_end_at = pass.recurrence_appointment_pass
            .recurrence_end_at
            ? moment(pass.recurrence_appointment_pass.recurrence_end_at).format(
                "MM/DD/YYYY"
              )
            : null
          state.form.recurrence_week =
            pass.recurrence_appointment_pass.recurrence_week
          state.form.time
        }
        formatMontlyWeekDay(state.form.for_date)
      }
    }

    const submit = () => {
      if (isFormValid.value) {
        state.form.time = state.form.time
          ? moment(state.form.time, "hh:mm A").format("HH:mm")
          : moment().format("HH:mm")
        const appointments = [
          {
            from_id: state.form.from_id
              ? state.form.from_id.value.id
              : state.form.from_id,
            to_id: state.form.to_id
              ? state.form.to_id.value.id
              : state.form.to_id,
            from_type: state.form.from_id
              ? state.form.from_id.value.type
              : state.form.from_id,
            to_type: state.form.to_id
              ? state.form.to_id.value.type
              : state.form.to_id,
            for_date: helpersJS.currTzDateTime(
              state.form.for_date,
              state.form.time
            ),
            for_time: state.form.time,
            period_id: state.form.period_id
              ? state.form.period_id.value
              : state.form.period_id,
            reason: state.form.reason,
            user_id: state.form.user_id
              ? state.form.user_id.value.id
              : state.form.user_id,
            recurrence_type: state.form.recurrence_type,
            recurrence_days: JSON.stringify({
              days: state.form.recurrence_days
            }),
            recurrence_end_at: state.form.recurrence_end_at
              ? helpersJS.currTzDate(state.form.recurrence_end_at)
              : null,
            recurrence_week:
              state.form.recurrence_type == "monthly"
                ? state.form.recurrence_week
                : null,
            child_id: state.form.child_id
          }
        ]
        editAppointmentPass(props.editablePass, appointments[0])
      } else {
        v$.value.$touch()
      }
    }

    const editAppointmentPass = (editData, data) => {
      const action = editData.recurrence_appointment_pass
        ? "adultAptPass/editRecurrenceAppPass"
        : "adultAptPass/editAppointmentPass"
      const id = editData.recurrence_appointment_pass
        ? editData.recurrence_appointment_pass.recurrence_appointment_pass_id
        : editData.id
      state.isProcessing = true
      store
        .dispatch(action, {
          id,
          data
        })
        .then(() => {
          setResponseInfoBox("Success", "Successfully updated!")
          state.isProcessing = false
          state.serverErrors = {}
          modal.setAction("createAppointments")
          setTimeout(() => {
            store.commit("adultAptPass/SET_EDITABLE_PASS", null)
            formatMontlyWeekDay(state.form.for_date)
            setResponseInfoBox()
            onCancel()
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.message ? res.message : {}
          setResponseInfoBox(res.status ? res.status : "Error", res.message)
          state.isProcessing = false
        })
    }

    const setWeekDay = (day) => {
      if (state.form.recurrence_days && state.form.recurrence_days.length) {
        if (state.form.recurrence_days.filter((el) => el === day).length) {
          state.form.recurrence_days = state.form.recurrence_days.filter(
            (el) => el !== day
          )
        } else {
          state.form.recurrence_days.push(day)
        }
      } else {
        state.form.recurrence_days.push(day)
      }
    }

    const checkForExistApp = () => {
      clearTimeout(state.timeoutForExistApp)
      state.timeoutForExistApp = setTimeout(() => {
        if (
          state.form.user_id &&
          state.form.for_date &&
          state.form.time &&
          (!props.editablePass ||
            (props.editablePass &&
              !props.editablePass.recurrence_appointment_pass))
        ) {
          const params = {
            studentID: state.form.user_id.value.id,
            apt_for_time: helpersJS.currTzDateTime(
              state.form.for_date,
              state.form.time
            )
          }
          if (props.editablePass) {
            params.apt_id = props.editablePass.id
          }
          store
            .dispatch("adultAptPass/getInfoForExistApp", params)
            .then((response) => {
              if (response.data) {
                state.appointment_exists_within_15_mins =
                  response.data.appointment_exists_within_15_mins
              }
            })
            .catch(() => {
              state.appointment_exists_within_15_mins = false
            })
        } else {
          state.appointment_exists_within_15_mins = false
        }
      }, 300)
    }

    const checkForRecurrenceEndTime = () => {
      if (
        state.form.for_date &&
        state.form.recurrence_end_at &&
        moment(state.form.recurrence_end_at).format("MM/DD/YYYY") <=
          moment(state.form.for_date).format("MM/DD/YYYY")
      ) {
        state.form.recurrence_end_at = null
      }
    }

    const resetRecurrenceOptions = () => {
      state.form.recurrence_days = []
      state.form.recurrence_week = null
      formatMontlyWeekDay(state.form.for_date)
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
      emit("closeModal")
    }

    const resetFormData = () => {
      state.form = {
        user_id: null,
        for_date: null,
        period_id: null,
        time: null,
        from_id: null,
        to_id: null,
        recurrence_type: null,
        recurrence_days: [],
        recurrence_end_at: null,
        reason: ""
      }
      setResponseInfoBox()
      state.serverErrors = {}
    }

    return {
      state,
      recurrenceOptions,
      weekDays,
      currentUser,
      activeTab,
      periods,
      meValue,
      defaultTo,
      defaultFrom,
      today,
      minDate,
      maxDate,
      minDateforRecurrenceEndAt,
      editablePassDate,
      isForDateEditable,
      formatedForDate,
      v$,
      isFormValid,
      errors,
      setFormData,
      formatMontlyWeekDay,
      submit,
      setWeekDay,
      checkForExistApp,
      checkForRecurrenceEndTime,
      resetRecurrenceOptions,
      setResponseInfoBox,
      onCancel,
      resetFormData
    }
  }
}
</script>
